.ant-select-selector {
  border: 1px solid white !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(39, 80, 88, 0.2); /* Adjust opacity as needed */
  z-index: 9999; /* Ensure the overlay is on top of everything */
}

.ant-layout-content {
  /* background-color: #000000e0 !important; */
}

.ant-btn-link {
  color: #275058;
}

.maxWidthCell {
  max-width: 100px;
}
